import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';

const Page = ({ location }) => (
  <DefaultLayout pageInfo={location}>
    <SEO title="Terms of Use" keywords={[]} />

    <Masthead location={location}>
      <Container className="my-4">
        <Row>
          <Col lg={6}>
            <h2>Terms of Use</h2>
          </Col>
          <Col
            className="text-right position-relative top-25 d-none d-lg-flex "
            lg={{ span: 3, offset: 2 }}
          />
        </Row>
      </Container>
    </Masthead>

    <Container className="mt-5">
      <Row>
        <Col>Hello content!</Col>
      </Row>
    </Container>
  </DefaultLayout>
);

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;
